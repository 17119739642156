<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>科目余额表</span>
        </div>
      </div>
      <div class="select-content" v-if="showDetails">
        <el-form ref="selectForm" v-model="selectForm" class="select-form">
          <div>
            <el-form-item label="组织名称">
              <el-input v-model.trim="selectForm.name" placeholder="请输入组织名称"
                        maxlength="32" @keyup.enter.native="submitForm()"/>
            </el-form-item>
            <el-form-item label="组织税号">
              <el-input v-model.trim="selectForm.taxNo" placeholder="请输入组织税号"
                        maxlength="20"@keyup.enter.native="submitForm()"/>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button @select="submitForm" @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main" v-if="showDetails">
      <el-table
        :data="tableData"
        style="width:100%"
        row-key="id"
        border
        stripe
        :header-cell-style="handleHeaderCellStyle"
        :default-expand-all="false"
        max-height="440"
        :expand-row-keys="[SecondaryNode]"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="组织名称" align="left"
                         :formatter="handleOrganizationName"></el-table-column>
        <el-table-column prop="taxNo" label="组织税号"></el-table-column>
        <el-table-column prop="operate" label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" style="width: 60px" @click="handleDetails(scope.row)"
                       :disabled="scope.row.taxNo.length<1">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <ent-subject-dict-details v-else :organization="organization"
                              @shutDetails="shutDetails"></ent-subject-dict-details>
  </div>
</template>

<script>
import {
  ReqOrganizationTree
} from '@/service/organization'
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins'
import EntSubjectDictDetails from './component/DeclareEntSubjectDictDetails'

export default {
  name: 'entSubjectDict',
  mixins: [tableStyle, dateOption, pagingMethods, changeMethods],
  components: {
    EntSubjectDictDetails
  },
  data () {
    return {
      selectForm: {
        name: undefined,
        taxNo: undefined,
      },
      organization: {},
      loading: 'false',
      tableData: [],
      tableLoading: false,
      SecondaryNode: '',
      showDetails: true
    }
  },
  created () {
    this.submitForm()
  },
  methods: {
    submitForm () {
      this.tableLoading = true
      ReqOrganizationTree(this.selectForm).then(res => {
        this.tableLoading = false
        if (res.success) {
          this.tableData = res.data
          //默认展开一级组织
          this.SecondaryNode = '' + this.tableData[0].id
        }
      })
    },
    //重置
    handleReset () {
      this.selectForm = {
        name: undefined,
        taxNo: undefined
      }
    },
    //组织名称长度不得超过14
    handleOrganizationName (row) {
      if (row && row.name.length > 14) {
        return row.name.substring(0, 14) + '...'
      } else {
        return row.name
      }
    },
    //详情
    handleDetails (row) {
      this.organization = row
      this.showDetails = false
    },
    shutDetails () {
      this.showDetails = true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/style/select.scss";

  .select-header {
    display: flex;
    flex-direction: row;
    height: 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 24px;

    .select-items {
      display: inline-flex;

      div {
        margin-right: 10px;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
      }

      input {
        border: none;
      }
    }
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }

  .content-main {
    margin: 24px 0 0 0;

    .content-table {
      ::v-deep .el-button {
        padding: 0;
      }
    }
  }

  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: left;
    padding: 8px 0;
  }
</style>
